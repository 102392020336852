.mode-dark .plan-history-table {
  color: #e5e7eb;
}

.plan-history-table {
  @apply animate-popoverfadein;
  width: 100%;
  border-width: 1px;
}

.plan-history-table thead {
  @apply font-semibold;
}

.plan-history-table th,
.plan-history-table td {
  padding: 0.25rem 0.75rem;
}

.plan-history-table td {
  border-top-width: 1px;
  border-right-width: 1px;
}

.plan-history-table tr {
  text-align: left;
}
