.tutorial-content h1,
.tutorial-content h2,
.tutorial-content h3,
.tutorial-content h4 {
  @apply text-lg leading-7 pb-4;
}

.tutorial-content ul {
  list-style: disc;
  padding: unset;
  padding-inline-start: 2rem;
  margin-bottom: 0.625rem;
}

.tutorial-content ol {
  list-style: auto;
  padding: unset;
  padding-inline-start: 2rem;
  margin-bottom: 0.625rem;
}

.tutorial-content p {
  @apply py-1.5;
}
