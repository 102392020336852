.codex-editor {
  z-index: auto;
}

.mode-dark .codex-editor .ce-block__content ::selection {
  background-color: #334155;
  color: white;
}

.mode-dark .codex-editor .ce-block--selected .ce-block__content {
  background-color: #111827;
}

.mode-dark .codex-editor .ce-block__content .ce-paragraph span {
  background-color: #334155 !important;
  color: white;
}

.codex-editor .ce-toolbar__content {
  margin: 0;
}

.codex-editor .ce-toolbar__content .ce-toolbar__actions {
  left: auto;
  right: calc(100% - 20px);
  background-color: #0083fc;
  padding: 4px;
  border-radius: 8px;
}

.codex-editor .ce-toolbar__content .ce-toolbar__actions .ce-toolbar__plus,
.codex-editor
  .ce-toolbar__content
  .ce-toolbar__actions
  .ce-toolbar__settings-btn {
  color: white;
}

.codex-editor
  .ce-toolbar__content
  .ce-toolbar__actions
  > .ce-toolbar__plus:hover,
.codex-editor
  .ce-toolbar__content
  .ce-toolbar__actions
  > .ce-toolbar__settings-btn:hover {
  background-color: #015bd7;
}

.mode-dark .codex-editor .cdx-settings-button svg path {
  fill: #d1d5db;
  stroke: transparent;
}

.mode-dark
  .codex-editor
  .cdx-settings-button.cdx-settings-button--active
  svg
  path {
  fill: #0083fc;
  stroke: transparent;
}

.mode-dark .codex-editor .cdx-search-field {
  background-color: transparent;
}

.mode-dark .codex-editor .cdx-search-field .cdx-search-field__icon svg {
  color: #0083fc;
}

@media (max-width: 768px) {
  .codex-editor .ce-toolbar__content .ce-toolbar__actions {
    right: calc(100% - 10px);
    z-index: 9999;
    flex-direction: column;
  }

  .codex-editor
    .ce-toolbar__content
    .ce-toolbar__actions
    .ce-toolbar__settings-btn {
    margin-left: 0;
  }

  .codex-editor .ce-toolbar__content .ce-toolbar__actions .ce-settings {
    top: 66px;
  }
}

@media (max-width: 650px) {
  .ce-toolbar__plus,
  .ce-toolbar__settings-btn {
    width: 26px;
    height: 26px;
    background-color: transparent;
    border: none;
  }
}

.mode-dark .ce-popover,
.mode-dark .ce-conversion-toolbar {
  background-color: #111827;
  color: white;
  border-color: #3b3953;
}

.mode-dark .ce-popover .ce-popover-item__icon,
.mode-dark .ce-conversion-toolbar .ce-conversion-tool__icon {
  color: #d1d5db;
  background-color: transparent;
  border-color: #1f2937;
}

.mode-dark .ce-popover .ce-popover-item {
  color: white;
}

.mode-dark .ce-popover .ce-popover-item:hover,
.mode-dark .ce-conversion-toolbar .ce-conversion-tool:hover {
  background-color: #1f2937;
}

.mode-dark .ce-inline-toolbar {
  background-color: #111827;
  border-color: #1f2937;
}

.mode-dark .ce-inline-toolbar .ce-inline-tool:hover,
.mode-dark .ce-inline-toolbar .ce-inline-toolbar__dropdown:hover:hover {
  background-color: #1f2937;
}

.mode-dark .ce-inline-toolbar .ce-inline-tool-input {
  background-color: #1f2937;
  border-color: #1f2937;
}

@media (max-width: 650px) {
  .ce-popover {
    min-width: 0;
    width: auto;
    bottom: 3.5rem;
  }
}

@media (min-width: 651px) {
  .codex-editor--narrow .ce-toolbox .ce-popover,
  .codex-editor--narrow .ce-settings .ce-popover {
    right: auto;
    left: 0;
  }
}

.ce-header {
  padding: 0 0;
}

.ce-header::after {
  left: 0 !important;
  bottom: 100%;
}

.ce-block__content {
  max-width: 100%;
  padding: 0 30px;
}

@media (max-width: 768px) {
  .ce-block__content {
    padding: 0 20px;
  }
}

.ce-block__content h1 {
  margin: 24px 0;
}

.ce-block__content h2 {
  margin: 20px 0;
}

.ce-block__content h3 {
  margin: 16px 0;
}

.ce-block__content h4 {
  margin: 12px 0;
}

.ce-block__content h5 {
  margin: 8px 0;
}

.ce-block__content h6 {
  margin: 8px 0;
}

/* Image plugin icons active color override */

.cdx-settings-button--active {
  color: #0083fc;
}

.ce-block__content [contenteditable='true']:empty:not(:focus):before {
  content: attr(data-placeholder);
  color: #8ea1b8;
  font-weight: 300;
  cursor: text;
  pointer-events: none;
}
