.mode-dark .permissions-table {
  color: #e5e7eb;
}

.permissions-table {
  @apply animate-popoverfadein;
  display: block;
  max-width: 100%;
}

.permissions-table th,
.permissions-table td {
  padding: 0.25rem 0.75rem;
}

.permissions-table td {
  border-top-width: 1px;
}

.permissions-table tr {
  text-align: left;
}
