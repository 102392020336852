/* Table row overrides */

.rs-table-row-header .rs-table-cell-group-fixed-left,
.rs-table-row-header .rs-table-cell-group-fixed-right,
.rs-table-row-header .rs-table-cell {
  overflow: visible;
  background-color: #f8f9fc;
}

.mode-dark .rs-table-row-header .rs-table-cell-group-fixed-left,
.mode-dark .rs-table-row-header .rs-table-cell-group-fixed-right,
.mode-dark .rs-table-row-header .rs-table-cell {
  background-color: #020617;
}

.rs-table-row {
  overflow: visible !important;
}

.mode-dark .rs-table-row {
  background-color: #0f172a;
}

.rs-table-cell-content {
  overflow: visible !important;
}

.rs-table-cell {
  overflow: visible !important;
}

.rs-table-row-header:hover {
  background-color: #f8f9fc;
}

.mode-dark .rs-table-row-header:hover {
  background-color: #020617;
}

.rs-table-row:not(.rs-table-row-header):hover .rs-table-cell {
  background-color: rgba(153, 205, 254, 1) !important;
}

.mode-dark .rs-table-row:not(.rs-table-row-header):hover {
  background-color: #1e293b !important;
}

.mode-dark .rs-table-row:not(.rs-table-row-header):hover .rs-table-cell {
  background-color: #1e293b !important;
}

/* Table header cell overrides */

.rs-table-cell-header .rs-table-cell .rs-table-cell-content {
  line-height: 1;
}

.rs-table-cell-header .rs-table-cell .rs-table-cell-content::after {
  content: '';
  display: block;
  height: 20px;
  border-right: 1px solid #8ea1b8;
  position: absolute;
  right: 0px;
}

.mode-dark .rs-table-cell-header .rs-table-cell .rs-table-cell-content::after {
  border-right: 1px solid #1e293b;
}

.rs-table-cell-header:last-child .rs-table-cell .rs-table-cell-content::after,
.cell-column .rs-table-cell .rs-table-cell-content::after {
  display: none;
}

.rs-table-cell-group
  > .rs-table-cell-first:not(.cell-column)
  .rs-table-cell-content,
.rs-table-cell-header:not(.cell-column)
  .rs-table-cell-first
  .rs-table-cell-content {
  padding-left: 0px !important;
}

.rs-table-hover .rs-table-row-header:hover .rs-table-cell-group,
.rs-table-hover .rs-table-row-header:hover .rs-table-cell {
  background-color: #f8f9fc;
}

.mode-dark .rs-table-hover .rs-table-row-header:hover .rs-table-cell-group,
.mode-dark .rs-table-hover .rs-table-row-header:hover .rs-table-cell {
  background-color: #020617;
}

/* Table cell overrides */

.rs-table-cell-header .rs-table-cell,
.rs-table-cell {
  background-color: transparent;
}

.mode-dark .rs-table-cell-header .rs-table-cell,
.mode-dark .rs-table-cell {
  border-color: #1e293b;
}

.mode-dark .rs-table-cell {
  background-color: #0f172a;
}

.mode-dark .rs-table-row-header {
  background-color: #020617;
}

.rs-table-row:not(.rs-table-row-header):nth-child(even)
  .rs-table-cell-group-fixed-right,
.rs-table-row:not(.rs-table-row-header):nth-child(even)
  .rs-table-cell-group-fixed-left,
.rs-table-row:not(.rs-table-row-header):nth-child(even),
.rs-table-row:not(.rs-table-row-header):nth-child(even) .rs-table-cell {
  background-color: #f8f9fc;
}

.mode-dark
  .rs-table-row:not(.rs-table-row-header):nth-child(even)
  .rs-table-cell-group-fixed-right,
.mode-dark
  .rs-table-row:not(.rs-table-row-header):nth-child(even).rs-table-cell-group-fixed-left,
.mode-dark .rs-table-row:not(.rs-table-row-header):nth-child(even),
.mode-dark
  .rs-table-row:not(.rs-table-row-header):nth-child(even)
  .rs-table-cell {
  background-color: #020617;
}

/* Dragged state for column header and cells */
.rs-table-row:not(.rs-table-row-header) .rs-table-cell.rs-dragged {
  background-color: rgba(153 205 254 / 0.2);
}

.rs-table-row:not(.rs-table-row-header):nth-child(even)
  .rs-table-cell.rs-dragged {
  background-color: rgba(153 205 254 / 0.5);
}

.rs-table-row.rs-table-row-header .rs-dragged {
  background-color: rgba(153 205 254 / 0.5);
}

.rs-table-row.rs-table-row-header .rs-dragged .header-filter {
  background-color: rgba(153 205 254 / 0.5);
  border-color: rgba(153 205 254 / 0.5);
}

.rs-table-row.rs-table-row-header .rs-dragged .header-filter * {
  opacity: 0.5;
}

/* Positioning overrides */

.rs-table-cell:hover {
  box-shadow: none;
}

.rs-table-cell-header .rs-table-cell .rs-table-cell-content,
.rs-table-cell .rs-table-cell-content {
  display: flex;
  align-items: center;
}

.rs-table-cell-wrap {
  max-width: 100%;
}

.rs-table-cell-header-sort-wrapper {
  position: absolute;
  right: 2px;
}

/* Tabe cell filters */
.rs-table-cell-header.include-filters .rs-table-cell-content {
  padding-bottom: 60px !important;
}

.rs-table-cell-header.include-filters .rs-table-cell {
  overflow: visible;
}

/* Resize */
.rs-table:not(.rs-table-column-resizing)
  .rs-table-cell-header
  .rs-table-cell:hover
  ~ .rs-table-column-resize-spanner,
.rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover {
  background-color: #a7a7a9;
}

.rs-table-column-resize-spanner {
  width: 3px;
  opacity: 0;
}

.rs-table-column-resize-spanner:hover {
  opacity: 1;
}

.rs-table-mouse-area > span,
.rs-table-mouse-area {
  background-color: #a7a7a9;
}
/** Checkbox overwrite*/
.rs-checkbox-inline {
  margin-left: 0;
}

/** Scrollbars */

.rs-table-scrollbar .rs-table-scrollbar-handle {
  opacity: 0.1;
  transition: opacity 150ms ease-in-out;
}

.rs-table-scrollbar:hover .rs-table-scrollbar-handle,
.rs-table-scrollbar:active .rs-table-scrollbar-handle,
.rs-table-scrollbar:focus .rs-table-scrollbar-handle {
  opacity: 0.6;
}

/* Fixed columns */

.rs-table-cell-group-fixed-left .rs-table-cell,
.rs-table-cell-group-fixed-left .header-filter {
  border-right: 1px solid rgb(229, 231, 235);
  box-shadow: none;
}

.mode-dark .rs-table-cell-group-fixed-left,
.mode-dark .rs-table-cell-group-fixed-left .header-filter {
  background-color: #020617;
}

.mode-dark .rs-table-cell-group-fixed-left .rs-table-cell,
.mode-dark .rs-table-cell-group-fixed-left .header-filter {
  border-right: 1px solid #1e293b;
}

.rs-table-cell-group-fixed-right .rs-table-cell {
  border-left: 1px solid rgb(229, 231, 235);
  box-shadow: none;
}

.mode-dark .rs-table-cell-group-fixed-right {
  background-color: #020617;
}

.mode-dark .rs-table-cell-group-fixed-right .rs-table-cell {
  border-left: 1px solid #1e293b;
}

/* Data grid action menu */

.rs-popover {
  background-color: transparent;
  border-radius: 0.5rem;
}

/* Data grid scrollbars */

.mode-dark .rs-table-scrollbar {
  background-color: transparent;
}

.rs-table-scrollbar.rs-table-scrollbar-horizontal {
  height: 10px;
}
.mode-dark .rs-table-scrollbar:hover {
  opacity: 1;
}

.mode-dark .rs-table-scrollbar .rs-table-scrollbar-handle {
  background-color: #3b3953;
  top: 0;
  opacity: 0.5;
}

.mode-dark .rs-table-scrollbar:hover .rs-table-scrollbar-handle {
  opacity: 1;
}

/* Data grid checkbox overrides */

.rs-checkbox .rs-checkbox-wrapper,
.rs-checkbox .rs-checkbox-wrapper::before,
.rs-checkbox .rs-checkbox-wrapper .rs-checkbox-inner::before {
  height: 1.25rem;
  width: 1.25rem;
}

.mode-dark .rs-checkbox .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #334155;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
  margin-top: 4px;
  margin-left: 7px;
}

.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
  margin-top: 2px;
  margin-left: 5px;
}
