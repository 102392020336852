.toastui-editor-defaultUI {
  border: none !important;
}

.toastui-editor-defaultUI-toolbar {
  border-top-right-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
  background-color: #f8f9fc !important;
}

.toastui-editor-mode-switch {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.toastui-editor-mode-switch,
.toastui-editor-defaultUI-toolbar,
.toastui-editor-defaultUI .tab-item {
  border-color: #dae3f2 !important;
}

.toastui-editor-defaultUI .tab-item.active {
  border-top-color: transparent !important;
}

.toastui-editor-toolbar-divider {
  background-color: #dae3f2 !important;
}

.toastui-editor-md-tab-container {
  display: none !important;
}

.full-size-editor > div {
  background: #fff;
  position: fixed !important;
  width: 100% !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100% !important;
  z-index: 20;
}

.toastui-editor-ww-container {
  background-color: transparent !important;
}

.toastui-editor-dropdown-toolbar {
  height: auto !important;
  max-width: calc(100vw - 6rem) !important;
  flex-wrap: wrap;
}

.disabled-editor .toastui-editor-defaultUI-toolbar,
.disabled-editor .toastui-editor-dropdown-toolbar {
  pointer-events: none;
}

.disabled-editor .toastui-editor-defaultUI-toolbar button {
  opacity: 40%;
}

.toastui-editor-media-button {
  background-image: url(../../images/icons-library/color/media-icon.svg) !important;
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
}

.toastui-editor-strech-button::before {
  display: inline-block;
  content: '';
  width: 100%;
  height: 100%;
  background-image: url(../../images/icons-library/arrows/arrow_stretch_thin-icon.svg) !important;
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.75;
}

.toastui-editor-contents img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
