.overflow-overlay {
  overflow: overlay;
  scrollbar-gutter: stable;
}

.overflow-x-overlay {
  overflow-x: overlay;
}

.overflow-y-overlay {
  overflow-y: overlay;
}

.scrollbar-sm::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(193, 193, 193, 0.7);
  border-radius: 6px;
  transition: background-color 0.3s ease-in-out;
}

.mode-dark ::-webkit-scrollbar-thumb {
  background-color: #3b3953;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #7f7f7f;
}

